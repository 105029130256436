import { TradingDocument } from "api/trading-documents/models";
import {
  EMPTY_VALUE,
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { ConfirmCheckbox } from "../shared/components/confirmCheckbox/ConfirmCheckbox";
import {
  isDateOlderThan24Hours,
  isDateOlderThan48Hours,
} from "../shared/utils/isDateOlderThan24Hours";
import { tradingDocumentConstants } from "constants/tradingDocuments";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import styles from "../tradingDocumentsList/TradingDocumentsList.module.css";
import { MoreDataCounter } from "components/common/moreDataCounter";
import { CountryFlag } from "components/common/countryFlag/CountryFlag";
import { cx, dateUtils, pluralize } from "utilities";
import { dateExceedsToday } from "../shared/utils/dateExceedsToday";
import { Avatar } from "components/miloDesignSystem/atoms/avatar";
import { Tag } from "components/miloDesignSystem/atoms/tag";

export const useCorrectionsColumns = () => {
  return useCreateTableColumns<TradingDocument>(({ columnHelper }) => {
    return [
      columnHelper.accessor(row => row, {
        id: "invoiceConfirmation",
        header: " ",
        size: 30,
        cell: info => {
          const tradingDocument = info.getValue();
          return <ConfirmCheckbox tradingDocument={tradingDocument} />;
        },
      }),
      columnHelper.accessor(row => row, {
        header: "nabywca",
        size: 200,
        cell: info => {
          const tradingDocument = info.getValue();
          return (
            <div className="d-flex align-items-center gap-1 justify-content-between w-100">
              <Typography className={styles.invoiceSpacing} fontSize="14" fontWeight="700" noWrap>
                {tradingDocument.recipientCompanyName ||
                  `${tradingDocument.recipientFirstName} ${tradingDocument.recipientLastName}`}
              </Typography>
              {!isDateOlderThan48Hours(tradingDocument.createdAt) && (
                <div
                  className={cx("d-flex align-items-center", {
                    [styles.highOpacity]: isDateOlderThan24Hours(tradingDocument.createdAt),
                  })}
                >
                  <Tag label="Nowa" type="outlined" variant="success" />
                </div>
              )}
            </div>
          );
        },
      }),
      columnHelper.text(row => row.signature, {
        header: "sygnatura",
        size: 140,
        typographyProps: {
          className: styles.invoiceSpacing,
          fontWeight: "500",
        },
      }),
      columnHelper.accessor(row => row.orders, {
        header: "nr zamówień",
        size: 120,
        cell: info => {
          const orders = info.getValue();
          return (
            <div className="d-flex align-items-center gap-1">
              {orders.slice(0, 1).map(order => (
                <Typography
                  className={styles.invoiceSpacing}
                  fontSize="12"
                  fontWeight="500"
                  key={order.id}
                >
                  {order?.signature || EMPTY_VALUE}
                </Typography>
              ))}
              {orders.filter(order => order).length - 1 > 0 && (
                <MoreDataCounter
                  counter={orders.filter(order => order).length - 1}
                  style={{ width: "max-content" }}
                />
              )}
            </div>
          );
        },
      }),
      columnHelper.text(
        row =>
          Boolean(row.reasons.length) ? (
            row.reasons
              .map(
                reason =>
                  tradingDocumentConstants.correctionReasonsMap[reason.field][reason.action],
              )
              .join(", ")
          ) : (
            <EmptyValue fontSize="12" fontWeight="500" />
          ),
        {
          header: "przyczyna",
          size: 130,
          typographyProps: {
            className: styles.invoiceSpacing,
            fontWeight: "500",
          },
        },
      ),
      columnHelper.accessor(row => row.countryCode, {
        header: "kraj",
        size: 50,
        cell: info => {
          const countryCode = info.getValue();
          if (!Boolean(countryCode.length)) return <EmptyValue fontSize="12" fontWeight="700" />;
          return <CountryFlag countryCode={countryCode} />;
        },
      }),
      columnHelper.text(row => row.masterDocument?.signature, {
        header: "faktura",
        size: 110,
        typographyProps: {
          className: styles.invoiceSpacing,
          fontWeight: "500",
        },
      }),
      columnHelper.accessor(row => row.invoiceIssueDate, {
        header: "wystawiono",
        size: 80,
        cell: info => {
          const invoiceIssueDate = info.getValue();
          if (!invoiceIssueDate) return <EmptyValue fontSize="12" fontWeight="500" />;
          return (
            <Typography
              className={styles.invoiceSpacing}
              color={dateExceedsToday(invoiceIssueDate) ? "danger600" : "neutralBlack88"}
              fontSize="12"
              fontWeight="500"
            >
              {dateUtils.formatDateToDisplay(invoiceIssueDate)}
            </Typography>
          );
        },
      }),
      columnHelper.accessor(row => row, {
        header: "termin",
        size: 120,
        cell: info => {
          const tradingDocument = info.getValue();
          return (
            <div className="d-flex align-items-center gap-1">
              <Typography
                className={styles.invoiceSpacing}
                color={
                  tradingDocument.paymentDeadline &&
                  dateExceedsToday(tradingDocument.paymentDeadline)
                    ? "danger600"
                    : "neutralBlack88"
                }
                fontSize="12"
                fontWeight="500"
              >
                {tradingDocument.paymentDeadline
                  ? dateUtils.formatDateToDisplay(tradingDocument.paymentDeadline)
                  : EMPTY_VALUE}
              </Typography>
              {tradingDocument.basePaymentDeadlineDelta && (
                <Typography className={styles.invoiceSpacing} fontSize="12" fontWeight="500">
                  ({tradingDocument.basePaymentDeadlineDelta}{" "}
                  {pluralize.pl(tradingDocument.basePaymentDeadlineDelta, {
                    singular: "dzień",
                    plural: "dni",
                    other: "dni",
                  })}
                  )
                </Typography>
              )}
            </div>
          );
        },
      }),
      columnHelper.text(row => row.signatureOfAnAuthorizedPerson, {
        header: "podpis",
        size: 75,
        typographyProps: {
          className: styles.invoiceSpacing,
          fontWeight: "500",
        },
      }),
      columnHelper.text(
        row =>
          row.latestMessageStatus?.length > 0 &&
          tradingDocumentConstants.tradingDocumentNotificationStatusDict[row.latestMessageStatus],
        {
          header: "wysyłka",
          size: 70,
          typographyProps: {
            className: styles.invoiceSpacing,
          },
        },
      ),
      columnHelper.accessor(row => row.assignedTo, {
        header: "przypisano",
        size: 70,
        cell: info => {
          const assignedTo = info.getValue();
          if (!assignedTo) return <EmptyValue fontSize="12" fontWeight="700" />;
          return (
            <div className="d-flex align-items-center gap-1 w-100">
              <Avatar user={assignedTo} size="xs" />
              <Typography className={styles.invoiceSpacing} fontSize="12" fontWeight="700" noWrap>
                {assignedTo.firstName} {assignedTo.lastName}
              </Typography>
            </div>
          );
        },
      }),
      columnHelper.text(
        row => row.sellerCompanyName || `${row.sellerFirstName} ${row.sellerLastName}`,
        {
          header: "sprzedawca",
          size: 250,
          typographyProps: {
            className: styles.invoiceSpacing,
            color: "neutralBlack48",
            fontSize: "12",
            fontWeight: "500",
          },
        },
      ),
    ];
  });
};
