import { tradingDocumentFileFactory } from "api/trading-documents/calls";
import { TradingDocument } from "api/trading-documents/models";
import { FileDownloadHandler } from "components/miloDesignSystem/atoms/fileDownloadHandler/FileDownloadHandler";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton/IconButton";
import { MdiDownloadXml } from "components/miloDesignSystem/atoms/icons/MdiDownloadXml";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip/Tooltip";

interface Props {
  selectedRows: TradingDocument[];
}

export const WaproFakirXml = ({ selectedRows }: Props) => {
  return (
    <FileDownloadHandler
      type="xml"
      factoryFn={() =>
        tradingDocumentFileFactory.fakirXml(selectedRows.map(tradingDocument => tradingDocument.id))
      }
    >
      {({ download, isLoading }) => (
        <Tooltip title="Pobierz Wapro fakir dla zaznaczonych">
          <IconButton
            isLoading={isLoading}
            icon={MdiDownloadXml}
            onClick={download}
            variant="transparent"
            theme="dark"
          />
        </Tooltip>
      )}
    </FileDownloadHandler>
  );
};
