import { languages } from "CONSTANTS";
import { tradingDocumentsActions } from "api/trading-documents/actions";
import { InvoiceType, TradingDocument } from "api/trading-documents/models";
import { Button } from "components/miloDesignSystem/atoms/button/Button";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton/IconButton";
import { MdiDownloadPdf } from "components/miloDesignSystem/atoms/icons/MdiDownloadPdf";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { Popover } from "components/miloDesignSystem/atoms/popover/Popover";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip/Tooltip";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { Select } from "components/miloDesignSystem/molecules/select/Select";
import { ClickOutsideHandler } from "components/utils/ClickOutsideHandler";
import { useState } from "react";

interface Props {
  invoiceType: InvoiceType;
  selectedRows: TradingDocument[];
}

export const TradingDocumentPdf = ({ invoiceType, selectedRows }: Props) => {
  const [language, setLanguage] = useState("");
  const handleSalesInvoicesBulkPdfDownload = tradingDocumentsActions.useSalesInvoicesBulkPdfDownload();
  const handleCorrectionInvoicesBulkPdfDownload = tradingDocumentsActions.useCorrectionInvoicesBulkPdfDownload();

  return (
    <Popover
      hideOnClickOutside={false}
      content={({ hide }) => (
        <ClickOutsideHandler
          onClickOutside={() => {
            hide();
            setLanguage("");
          }}
          outsideClickIgnoreClass={ignoreClickOutsideClassName}
        >
          <div>
            <Typography className="mt-2 mb-3" fontSize="14" fontWeight="700">
              Wybierz język faktury
            </Typography>
            <div className="d-flex">
              <div>
                <Select
                  items={languages.map(language => ({
                    value: language.value,
                    text: language.language,
                    type: MenuItemType.TEXT,
                  }))}
                  onChange={countryCode => countryCode && setLanguage(String(countryCode))}
                  label="Wybierz język"
                  selected={language}
                />
              </div>
            </div>
            <div className="d-flex flex-1 justify-content-end mt-3">
              <Button
                className="text-uppercase w-100"
                size="small"
                variant="deepPurple"
                onClick={() => {
                  if (invoiceType === "CORRECTION") {
                    handleCorrectionInvoicesBulkPdfDownload(
                      selectedRows.map(tradingDocument => tradingDocument.id),
                      language,
                    );
                  } else {
                    handleSalesInvoicesBulkPdfDownload(
                      selectedRows.map(tradingDocument => tradingDocument.id),
                      language,
                    );
                  }
                  hide();
                }}
              >
                Pobierz PDFy wybranych faktur
              </Button>
            </div>
          </div>
        </ClickOutsideHandler>
      )}
      variant="DARK"
    >
      <div>
        <Tooltip title="Pobierz PDF dla zaznaczonych">
          <IconButton icon={MdiDownloadPdf} variant="transparent" theme="dark" />
        </Tooltip>
      </div>
    </Popover>
  );
};

const ignoreClickOutsideClassName = "trading-documents-list-popover-ignore-class-name";
